import React, { useState } from 'react';
import AdminLayout from '../components/AdminLayout';
import axios from 'axios'; // Import axios for making HTTP requests

const AdminAddUser = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Reset error and success message
    setError('');
    setSuccessMessage('');

    // Create the user object
    const user = {
      name,
      email,
      phoneNumber,
      password,
    };

    try {
      // Replace '/api/add-user' with your actual API endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/add-user`, user,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      
      // Handle success response
      setSuccessMessage('User added successfully!');
      // Optionally, reset the form
      setName('');
      setEmail('');
      setPhoneNumber('');
      setPassword('');
    } catch (err) {
      // Handle error response
      setError('Failed to add user. Please try again.');
    }
  };

  return (
    <AdminLayout activeTab={"Add User"}>
      <div className="max-w-md mx-auto mt-10 p-5 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-white mb-4">Add User</h2>
        {error && <p className="text-red-500">{error}</p>}
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full p-2 text-black rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 text-black rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="w-full p-2 text-black rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 text-black rounded"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded"
          >
            Add User
          </button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AdminAddUser;
