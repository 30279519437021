import React, { useEffect, useState } from 'react';
import OrderRow from './UserRow';

export default function OrderTable({ initialUsers }) {
  const [users, setUsers] = useState(initialUsers);

  const handleDeleteUser = (userId) => {
    // Update state to remove the deleted user
    setUsers(prevUsers => prevUsers.filter(user => user._id !== userId));
  };

  return (
    <div className="bg-black p-6 rounded-lg mt-4">
      <div className="flex justify-between mb-4">
        <div>
          <h2 className="text-xl font-semibold text-white">All Users</h2>
        </div>
        <div className="flex gap-4">
          <button className="bg-gray-800 text-white px-4 py-2 rounded-lg">Filter</button>
          <button className="text-blue-500">See All</button>
        </div>
      </div>
      <table className="w-full text-left">
        <thead>
          <tr className="bg-gray-700">
            <th className="p-3 text-white">Customer</th>
            <th className="p-3 text-white">Email</th>
            <th className="p-3 text-white">Phone Number</th>
            <th className="p-3 text-white">Status</th>
            <th className="p-3 text-white">Created At</th>
            <th className="p-3 text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <OrderRow
              key={user._id}
              Id={user._id}
              name={user.name}
              email={user.email}
              phoneNumber={user.phoneNumber}
              isAdmin={user.isAdmin ? 'Admin' : 'User'}
              createdAt={user.createdAt}
              onDelete={handleDeleteUser} // Pass the delete handler to OrderRow
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
