import React from 'react'
import PlatinumDetails from '../components/PlatinumDetails'
import AdminLayout from '../components/AdminLayout'

function Platinum() {
  return (
    <AdminLayout activeTab={"Platinum"}>
    <PlatinumDetails/>
  </AdminLayout>
  )
}

export default Platinum