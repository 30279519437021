import React from 'react'
import GoldDetails from '../components/GoldDetails'
import AdminLayout from '../components/AdminLayout'

function Gold() {
  return (
    <AdminLayout activeTab={"Gold"}>
    <GoldDetails/>
  </AdminLayout>
  )
}

export default Gold