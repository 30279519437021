import React from 'react';
import graph1 from '../images/graph1.png';
import graph2 from '../images/graph2.png';
import graph3 from '../images/graph3.png';
import graph4 from '../images/graph4.png';
import graph5 from '../images/graph5.png';
import graph6 from '../images/graph6.png';
import graph7 from '../images/graph7.png';
import graph9 from '../images/graph9.png';
import graph10 from '../images/graph10.png';
import graph11 from '../images/graph11.png';
import graph12 from '../images/graph12.png';
import graph13 from '../images/graph13.png';
import maingraph from '../images/maingraph.png';

const Graphs = () => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 pt-4">
      
      <div>
        <img src={graph10} alt="Gold Graph" className="w-full" />
      </div>

      <div className="text-container p-4">

        <h1 className="text-2xl font-bold mb-2 text-black">What goes down comes back up</h1>
        <p className="text-base leading-relaxed text-gray-700">
          Stock market crashes can rattle even the most experienced investors, but focusing on the long term is key to surviving a downturn. 
          The stock market will always experience dips on a regular basis, so it's more important to look at the big picture and see how the market performs over time.
        </p>
      </div>
      

      <div>
        <img src={graph11} alt="Stock Graph" className="w-full" />
      </div>

      

      <div className="grid grid-cols-2 gap-4">
        <div>
          <img src={graph12} alt="Gold Graph" className="w-full" />
        </div>
        <div>
          <img src={graph13} alt="Silver Graph" className="w-full" />
        </div>
        <div>
          <img src={graph9} alt="Copper Graph" className="w-full" />
        </div>
        <div>
          <img src={graph5} alt="Zinc Graph" className="w-full" />
        </div>
        <div>
          <img src={graph6} alt="Zinc Graph" className="w-full" />
        </div>
        <div>
          <img src={graph7} alt="Zinc Graph" className="w-full" />
        </div>
        {/* Uncomment these if you need to display them
        <div>
          <img src={graph6} alt="Cotton Candy Graph" className="w-full" />
        </div>
        <div>
          <img src={graph7} alt="Crudeoil Graph" className="w-full" />
        </div> */}
      </div>
    </div>
  );
};

export default Graphs;
