import React, { useState } from 'react';
import UserLayout from '../components/UserLayout';
import StockDataTable from '../components/StockDataTable'; // Adjust the import path as necessary
import StockChart from '../components/StockChart';
import img1 from '../images/img1.jpg';
import img2 from '../images/img2.jpg';
import img3 from '../images/img3.png';
import graph8 from "../images/graph8.png";
import graph9 from "../images/graph9.png";

const UserDashboard = () => {
  const [activeTab, setActiveTab] = useState("Bullion");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <UserLayout activeTab="Dashboard">
      <div className="bg-gray-900 text-white"> {/* Dark background with white text */}
        

        <div className="mb-4">
          <div className="flex space-x-6">
            <button
              onClick={() => handleTabClick("Bullion")}
              className={`text-lg font-semibold ${
                activeTab === "Bullion" ? "text-white border-b-2 border-white" : "text-gray-400"
              }`}
            >
              Bullion
            </button>
            <button
              onClick={() => handleTabClick("Energy")}
              className={`text-lg font-semibold ${
                activeTab === "Energy" ? "text-white border-b-2 border-white" : "text-gray-400"
              }`}
            >
              Energy
            </button>
            <button
              onClick={() => handleTabClick("Metals")}
              className={`text-lg font-semibold ${
                activeTab === "Metals" ? "text-white border-b-2 border-white" : "text-gray-400"
              }`}
            >
              Metals
            </button>
          </div>
        </div>

        <StockDataTable />
      </div>
    </UserLayout>
  );
};

export default UserDashboard;
