import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

const AdminLayout = ({ activeTab, children }) => {
  // State to manage the sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div style={{ background: '#171717' }} className="flex h-screen text-white">
      <Sidebar activeTab={activeTab} isOpen={isSidebarOpen} />
      
      <main className="flex-1 p-6 overflow-y-auto h-full">
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        {children} {/* This will render the child components */}
      </main>
      
      {/* Overlay for mobile when sidebar is open */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default AdminLayout;
