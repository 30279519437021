import React, { useState } from 'react';
import axios from 'axios';
import UserLayout from '../components/UserLayout';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  // You can get your token from local storage or any other state management solution
  const token = localStorage.getItem('token'); // Adjust this according to your token storage

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitSuccess(false);  // Reset the success state
    setSubmitError(false);    // Reset the error state

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/users/contact-us`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.data); // Handle the success response
      setSubmitSuccess(true); // Set success state
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError(true); // Set error state
    }
  };

  return (
    <UserLayout activeTab="Contact Us">
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Contact Us</h2>
        {submitSuccess && (
          <p className="text-green-600 text-center mb-4">Thank you for reaching out! We'll get back to you soon.</p>
        )}
        {submitError && (
          <p className="text-red-600 text-center mb-4">There was an error sending your message. Please try again.</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-600 mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-1">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
            Send Message
          </button>
        </form>
      </div>
    </div>
    </UserLayout>
  );
}

export default ContactUs;
