// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './components/Navbar';
import Login from './pages/Login';
//import Signup from './pages/Signup';
import AdminDashboard from './pages/AdminDashboard';
import UserDashboard from './pages/UserDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AdminAddUser from './pages/AdminAddUser';
import AdminBulkUpload from './pages/AdminBulkUpload';
import ContactUs from './pages/Contactus';
import AboutUs from './pages/AboutUs';
import PlatinumDetails from './components/PlatinumDetails';
import Platinum from './pages/Platinum';
import Gold from './pages/Gold';
import Silver from './pages/Silver';


function App() {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
      
        
        {/* Protected routes */}
        <Route path="/admin-dashboard" element={<ProtectedRoute component={AdminDashboard} />} />
        <Route path="/admin-add-user" element={<ProtectedRoute component={AdminAddUser} />} />
        <Route path="/admin-bulk-upload" element={<ProtectedRoute component={AdminBulkUpload} />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/platinum" element={<Platinum />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/silver" element={<Silver />} />
        {/* Default route */}

        <Route path="/" element={<UserDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
