import React from 'react';
import { Link } from 'react-router-dom';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PagesIcon from '@mui/icons-material/Pages';
import LockIcon from '@mui/icons-material/Lock';
import LayoutIcon from '@mui/icons-material/Apps';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

export default function UserSidebar({ activeTab, isOpen, setIsOpen }) {
  const menuSections = [
    {
      title: 'Main',
      items: [
        { name: 'Dashboard', icon: <DashboardCustomizeIcon />, path: '/user-dashboard', notification: '9+' },
      ],
    },
    {
      title: 'App',
      items: [
        { name: 'Contact Us', icon: <PersonAddAltIcon />, path: '/contact-us' },
        { name: 'About Us', icon: <ViewKanbanIcon />, path: '/about-us' }
      ],
    },
  ];

  return (
    <>
      {/* Overlay for mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <aside
        className={`fixed lg:static lg:translate-x-0 transition-transform duration-300 ease-in-out bg-gray-900 text-white p-4 h-full z-20 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:w-30 w-58 lg:w-64`}
      >
        {/* Sidebar Header */}
        <h2 className="text-2xl font-bold mb-6 text-white flex items-center justify-center 
              bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg py-3 px-6 
              shadow-lg hover:from-purple-700 hover:to-blue-700 transition-all duration-300">
          <span className="mr-2">🔷</span>
          MCX
        </h2>

        <nav>
          {menuSections.map((section, index) => (
            <div key={index} className="mb-6">
              <h3 className="text-sm font-semibold text-gray-400 uppercase mb-2">{section.title}</h3>
              <ul className="space-y-4 pl-0">
                {section.items.map((item) => (
                  <li
                    key={item.name}
                    className={`flex items-center text-lg p-2 rounded cursor-pointer ${
                      activeTab === item.name ? 'bg-gray-700 text-blue-500' : 'text-gray-300'
                    } hover:bg-gray-700 hover:text-blue-500 transition`}
                  >
                    <Link
                      to={item.path}
                      className="flex items-center w-full text-inherit no-underline"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="mr-3">{item.icon}</span>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </aside>
    </>
  );
}
