import React from 'react';
import UserLayout from '../components/UserLayout';

function AboutUs() {
  return (
    <UserLayout activeTab="About Us">
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <div className="max-w-3xl w-full p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">About Us</h1>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">
          Welcome to [Your Company Name], where innovation meets dedication. Since our inception, we’ve strived to
          deliver unparalleled quality and value in every product and service we offer.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">
          Our team is comprised of industry experts who are passionate about bringing the best solutions to our
          customers. From [core services/products] to [unique selling points], we are committed to excellence and
          continuous improvement.
        </p>
        <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-2">Our Mission</h2>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">
          Our mission is to empower our clients with cutting-edge tools and resources that promote growth and success.
          We believe in the power of collaboration and are dedicated to creating a positive impact in the communities
          we serve.
        </p>
        <h2 className="text-2xl font-bold text-gray-800 mt-6 mb-2">Why Choose Us?</h2>
        <ul className="list-disc list-inside text-gray-700 text-lg mb-4">
          <li>Experienced professionals with a commitment to excellence</li>
          <li>Customer-centric approach focused on your unique needs</li>
          <li>Innovative solutions designed for long-term success</li>
          <li>Dedicated support and guidance every step of the way</li>
        </ul>
        <p className="text-gray-700 text-lg leading-relaxed">
          Thank you for considering us as your trusted partner. We look forward to working with you and helping you
          achieve your goals.
        </p>
      </div>
    </div>
    </UserLayout>
  );
}

export default AboutUs;
