import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

export default function OrderRow({ Id, name, email, phoneNumber, isAdmin, createdAt, onDelete }) {
  const statusClass = {
    'Admin': 'bg-green-600 text-green-100',
    'User': 'bg-blue-600 text-blue-100',
  }[isAdmin] || 'bg-gray-600 text-gray-100';

  const deleteUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/delete-user/${Id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to delete user');
      // Call the onDelete function to notify parent component
      onDelete(Id);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <tr className="border-b border-gray-700">
      <td className="p-2">{name}</td>
      <td className="p-2">{email}</td>
      <td className="p-2">{phoneNumber}</td>
      <td className="p-2">
        <span className={`px-2 py-1 rounded-full ${statusClass}`}>{isAdmin}</span>
      </td>
      <td className="p-2">{new Date(createdAt).toLocaleDateString()}</td>
      <td className="p-2">
        <button onClick={deleteUser} className="text-red-500">
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
}
