// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ component: Component }) {
  const isAuthenticated = localStorage.getItem('token'); // Check for token in localStorage
  const userRole = localStorage.getItem('isAdmin'); // Assuming role is stored in localStorage



  // If the user is not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Add role-based access control
  if (userRole === 'true') { // Check if the user role indicates an admin
    return <Component />; // If the user is an admin, render the protected component
  } else {
    return <Navigate to="/user-dashboard" />; // Redirect to user dashboard if not an admin
  }
}

export default ProtectedRoute;
