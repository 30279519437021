// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className=" text-black py-6 border-t border-gray-200">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
        <div className="text-sm text-center md:text-left mb-4 md:mb-0">
          © 2024 MCX. All rights reserved.
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 text-center md:text-right">
          <a href="#" className="hover:underline">Privacy Policy</a>
          <a href="#" className="hover:underline">Terms of Service</a>
          <a href="#" className="hover:underline">Contact Us</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
