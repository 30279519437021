import React from 'react';

const Card = ({ title, subtitle, description, listItems, buttons }) => {
  return (
    <div className="bg-gray-800 border border-gray-600 p-6 rounded-lg shadow-lg flex flex-col h-full"> {/* Dark mode styling */}
      <h2 className="text-2xl font-bold text-white"> {/* White text for dark mode */}
        {title}
        {subtitle && <span className="text-red-400 font-normal"> {subtitle}</span>}
      </h2>
      <p className="text-gray-300 mt-4"> {/* Lighter text color for readability */}
        {description}
      </p>

      {listItems && listItems.length > 0 && (
        <ul className="text-gray-300 font-semibold mt-4 space-y-2"> {/* Adjusted text color */}
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}

      {buttons && buttons.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-6">
          {buttons.map((button, index) => (
            <a
              key={index}
              href={button.link}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              {button.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Card;
