import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardGraphs = () => {
  const barData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Daily Orders',
        data: [1200, 1500, 900, 1100, 1600, 1400, 1800],
        backgroundColor: '#2563eb', // Blue color
        borderRadius: 5,
      },
    ],
  };

  const lineData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Active Users',
        data: [1300, 1000, 1500, 900, 1200, 800, 1100],
        fill: false,
        borderColor: '#2563eb', // Line color
        tension: 0.3,
      },
    ],
  };

  const barOptions = {
    plugins: { legend: { display: false } },
    scales: {
      x: { grid: { display: false }, ticks: { color: '#ffffff' } },
      y: { grid: { color: '#333' }, ticks: { color: '#ffffff' } },
    },
  };

  const lineOptions = {
    plugins: { legend: { display: false } },
    scales: {
      x: { grid: { display: false }, ticks: { color: '#ffffff' } },
      y: { grid: { color: '#333' }, ticks: { color: '#ffffff' } },
    },
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {/* Daily Average Orders Card */}
      <div className="bg-gray-800 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-white text-lg font-semibold">Daily average orders</h3>
          <span className="text-green-500 text-sm">+1.33% vs last month</span>
        </div>
        <p className="text-white text-2xl font-bold">$1,226.71k</p>
        <Bar data={barData} options={barOptions} />
      </div>

      {/* Active Users Card */}
      <div className="bg-gray-800 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-white text-lg font-semibold">Active users</h3>
          <span className="text-red-500 text-sm">-5.27% vs last month</span>
        </div>
        <p className="text-white text-2xl font-bold">1,226.71k</p>
        <Line data={lineData} options={lineOptions} />
      </div>
    </div>
  );
};

export default DashboardGraphs;
