import React, { useState } from 'react';
import Header from './Header';
import UserSidebar from './UserSidebar';
import UserHeader from './UserHeader';
import Footer from './Footer';
import Card from './Card';
import Card2 from './Card2';
import footer1 from '../images/footer1.jpg';
import footer2 from '../images/footer2.jpg';
import footer3 from '../images/footer3.jpg';
import Graphs from './Graphs';

const UserLayout = ({ activeTab, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div style={{ background: '#1a202c' }} className="flex h-screen text-white"> {/* Dark background */}
        <UserSidebar activeTab={activeTab} isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
        <main className="flex-1 overflow-y-auto h-full flex flex-col bg-gray-800"> {/* Dark main section */}
          <UserHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
          <hr className="text-gray-500 w-full" /> {/* Darker divider */}
          <div className="flex-1 overflow-y-auto">
            {children} {/* This will render the child components */}
            <Graphs />
            <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
              <Card
                title="Trade"
                subtitle="with MCX"
                description="We offer a robust and regulated exchange for you to mitigate risks through investments and trade in commodities with ease."
                listItems={[
                  "Trading-cum-Clearing Member (TCM)",
                  "Institutional Trading-cum-Clearing Member (ITCM)",
                  "Professional Clearing Member (PCM)",
                  "Trading Member (TM)",
                ]}
                buttons={[
                  { label: 'Become a member', link: '#' },
                ]}
              />
              <Card
                title="MCX Certified Commodity Professional (MCCP)"
                description="Get acquainted with the commodity derivatives market. The programme focuses on the modalities of trading in commodity markets, its operations and practices, clearing, settlement and delivery procedures, and its regulatory and legal framework."
                buttons={[
                  { label: 'Enroll Now', link: '#' },
                  { label: 'Training', link: '#' },
                ]}
              />
              <Card
                title="What is Hedging?"
                description="A technique of neutralising risk by taking a position in the derivatives market that is opposite to one’s position in the cash market, thereby reducing or limiting the effect of risk associated with unpredictable changes in price."
                buttons={[
                  { label: 'Read on..', link: '#' },
                ]}
              />
            </div>
            <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
              <Card2
                image={footer1}
                title="Dabba Trading is an illegal activity, wherein trades are carried out outside Exchange Trading Platform & Investors are not protected against non-receipt of Funds, Commodities & Securities. Click here for LIST OF FAKE TRADING APPS/ENTITIES"
              />
              <Card2
                image={footer2}
                title="Compliance Calendar is a consolidated checklist which helps members to comply with various requirements of compliance and submissions. Read on.."
              />
              <Card2
                image={footer3}
                title="Eligible entities to follow a process to get empanelled themselves as Warehouse Service Provider and cater to the storage requirements of various market participants. Read on.."
              />
            </div>
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
};

export default UserLayout;
