import React from 'react'
import SilverDetails from '../components/SilverDetails'
import AdminLayout from '../components/AdminLayout'

function Silver() {
  return (
    <AdminLayout activeTab={"Silver"}>
    <SilverDetails/>
  </AdminLayout>
  )
}

export default Silver