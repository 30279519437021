import { Link } from 'react-router-dom';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PagesIcon from '@mui/icons-material/Pages';
import LockIcon from '@mui/icons-material/Lock';
import LayoutIcon from '@mui/icons-material/Apps';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

export default function Sidebar({ activeTab, isOpen }) {
  const menuSections = [
    {
      title: 'Main',
      items: [
        { name: 'Dashboard', icon: <DashboardCustomizeIcon />, path: '/admin-dashboard', notification: '9+' },
      ],
    },
    {
      title: 'App',
      items: [
        { name: 'Add User', icon: <PersonAddAltIcon />, path: '/admin-add-user' },
        { name: 'Bulk Upload Users', icon: <ViewKanbanIcon />, path: '/admin-bulk-upload' },
        { name: 'Platinum', icon: <ReceiptIcon />, path: '/platinum' },
        { name: 'Gold', icon: <ReceiptIcon />, path: '/gold' },
        { name: 'Silver', icon: <ReceiptIcon />, path: '/silver' },
      ],
    },
    {
      title: 'Extra Pages',
      items: [
        { name: 'Pages', icon: <PagesIcon />, path: '/pages' },
        { name: 'Authentication', icon: <LockIcon />, path: '/authentication' },
        { name: 'Layouts', icon: <LayoutIcon />, path: '/layouts', new: true },
      ],
    },
  ];

  return (
    <aside
      className={`fixed lg:static top-0 left-0 w-64 bg-black p-4 h-screen overflow-y-auto transition-transform duration-300 ease-in-out z-20
        ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}
    >
      <h2 className="text-2xl font-bold mb-6 text-white flex items-center justify-center 
            bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg py-3 px-6 
            shadow-lg hover:from-purple-600 hover:to-blue-600 transition-all duration-300">
        <span className="mr-2">🔷</span>
        MCX
      </h2>

      <nav>
        {menuSections.map((section, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-sm font-semibold text-white uppercase mb-2">{section.title}</h3>
            <ul className="space-y-4 pl-0">
              {section.items.map((item) => (
                <li
                  key={item.name}
                  className={`flex items-center text-lg p-2 rounded cursor-pointer ${
                    activeTab === item.name ? 'bg-gray-800 text-blue-500' : 'text-white'
                  } hover:bg-gray-800 hover:text-blue-500 transition`}
                >
                  <Link
                    to={item.path}
                    className="flex items-center w-full text-inherit no-underline"
                  >
                    <span className="mr-3">{item.icon}</span>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </aside>
  );
}
