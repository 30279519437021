// src/pages/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OrderTable from '../components/UserTable';
import DashboardGraphs from '../components/DashboardGraphs';
import '../App.css';
import AdminLayout from '../components/AdminLayout';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-all-users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  if (loading) return <div className="text-white text-center">Loading...</div>;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <AdminLayout activeTab="Dashboard">
      <DashboardGraphs />
      <OrderTable initialUsers={users} />
    </AdminLayout>
  );
};

export default AdminDashboard;
