import React, { useState } from 'react';
import AdminLayout from '../components/AdminLayout';
import * as XLSX from 'xlsx'; // Import the xlsx library
import axios from 'axios';

const AdminBulkUploadUsers = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const token = localStorage.getItem('token');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setError('');
    setSuccessMessage('');

    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    // Read the file as an array buffer
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Get the first worksheet
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const users = XLSX.utils.sheet_to_json(worksheet); // Convert to JSON
        console.log(users);
      try {
        // Send the parsed user data to the backend
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/bulk-upload`, {users},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        // Handle successful response
        setSuccessMessage(response.data.message);
        setFile(null); // Clear the file input
      } catch (err) {
        // Handle error response
        setError('Failed to bulk upload users. Please try again.');
      }
    };

    reader.onerror = () => {
      setError('Error reading file. Please try again.');
    };

    reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
  };

  return (
    <AdminLayout activeTab={"Bulk Upload Users"}>
      <div className="max-w-md mx-auto mt-10 p-5 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-white mb-4">Bulk Upload Users</h2>
        {error && <p className="text-red-500">{error}</p>}
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="file">Excel File (.xlsx)</label>
            <input
              type="file"
              id="file"
              accept=".xlsx"
              onChange={handleFileChange}
              required
              className="w-full p-2 text-black rounded"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded"
          >
            Upload Users
          </button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AdminBulkUploadUsers;
