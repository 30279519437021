import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import '../styles/stockChart.css';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StockChart = () => {
  // Expanded mock data for the main line chart (MCX iCOMDEX Index)
  const lineData = {
    labels: [
      '9:30', '10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30',
      '17:30', '18:30', '19:30', '20:30', '21:30',
    ],
    datasets: [
      {
        label: 'MCX iCOMDEX Base Metal',
        data: [
          17720.95, 17750.30, 17800.25, 17810.45, 17850.60, 17870.00, 17890.75,
          17920.50, 17900.5, 17930.00, 17910.85, 17940.30, 17905.26,
        ],
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        fill: true,
      },
    ],
  };

  // Mock data for individual commodity charts with multiple data points for better visualization
  const createBarData = (label, data, color) => ({
    labels: ['1st', '2nd', '3rd', '4th', '5th'],
    datasets: [
      {
        label,
        data,
        backgroundColor: color,
      },
    ],
  });

  return (
    <div className="market-activity">
      <h2>Market Activity</h2>
      <div className="main-chart">
        <Line data={lineData} options={{ responsive: true, maintainAspectRatio: false }} />
      </div>
      <div className="commodity-charts">
        <Bar
          data={createBarData('Gold', [78450, 78500, 78300, 78600, 78400], 'gold')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Silver', [94677, 94700, 94500, 94850, 94600], 'silver')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Crude Oil', [6111, 6130, 6100, 6150, 6120], '#b5651d')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Natural Gas', [230, 235, 225, 240, 232], 'blue')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Copper', [859.5, 860, 855, 862, 858], 'orange')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Zinc', [287, 290, 285, 295, 288], '#c0c0c0')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Cotton Candy', [55700, 55800, 55600, 55900, 55750], '#ffcccb')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
        <Bar
          data={createBarData('Lead', [182.05, 183, 181, 184, 182.5], 'grey')}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
    </div>
  );
};

export default StockChart;