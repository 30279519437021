import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import mcxLogo from '../images/MCX_logo.png';

export default function Header({ toggleSidebar, isSidebarOpen }) {
  return (
    <header
      style={{ background: '#1f1f1f' }} // Dark background color
      className="flex justify-between items-center px-4 py-2 md:py-4 text-white"
    >
      {/* Hamburger Menu Icon */}
      <button onClick={toggleSidebar} className="lg:hidden text-white">
        {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
      </button>

      <div className="flex items-center">
        <img src={mcxLogo} alt="logo" className="h-8" />
      </div>

      <div className="flex items-center space-x-2 md:space-x-4">
        {/* Language Selector */}
        <div className="hidden sm:flex bg-gray-800 p-2 rounded-full text-white text-sm md:text-base">
          EN
        </div>
        {/* Notification Icon */}
        <div className="bg-gray-800 p-2 rounded-full text-white text-sm md:text-base">
          🔔
        </div>
        {/* Profile Icon */}
        <div className="bg-gray-800 p-2 rounded-full text-white text-sm md:text-base">
          👤
        </div>
      </div>
    </header>
  );
}
