import React from 'react';

const Card2 = ({ image, title }) => {
  return (
    <div className="bg-gray-800 border border-gray-600 p-6 rounded-lg shadow-lg flex flex-col h-full"> {/* Dark mode styling */}
      {/* Image */}
      {image && (
        <img
          src={image}
          alt={title}
          className="w-full h-48 object-cover rounded-md mb-4"
        />
      )}
      
      {/* Title */}
      <h6 className="font-bold text-white"> {/* White text for dark mode */}
        {title}
      </h6>
    </div>
  );
};

export default Card2;
