import React, { useState, useEffect } from 'react';

function PlatinumDetails() {
  const [openPrice, setOpenPrice] = useState('');
  const [highPrice, setHighPrice] = useState('');
  const [lowPrice, setLowPrice] = useState('');
  const [openInterestChange, setOpenInterestChange] = useState('');
  const [message, setMessage] = useState('');

  // Fetch the initial platinum data when the component mounts
  useEffect(() => {
    const fetchPlatinumData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/get-platinum`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Ensure the token is properly passed
          },
        });

        const data = await response.json();
        console.log(data);
        if (response.ok) {
          setOpenPrice(data.platinum.openPrice);
          setHighPrice(data.platinum.highPrice);
          setLowPrice(data.platinum.lowPrice);
          setOpenInterestChange(data.platinum.openInterestChange);
        } else {
          setMessage('Error fetching platinum details');
        }
      } catch (error) {
        console.error('Error:', error);
        setMessage('Server error');
      }
    };

    fetchPlatinumData();
  }, []); // Empty dependency array to run this effect only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/platinum-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          openPrice: parseFloat(openPrice),
          highPrice: parseFloat(highPrice),
          lowPrice: parseFloat(lowPrice),
          openInterestChange: parseFloat(openInterestChange)
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage('Error updating platinum details');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Server error');
    }
  };

  return (
    <div className="bg-gray-900 text-gray-100 p-6 rounded-lg max-w-md mx-auto mt-4">
      <h2 className="text-xl font-semibold text-center mb-4">Platinum Details</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 font-medium">Change Price:</label>
          <input
            type="number"
            value={openInterestChange}
            onChange={(e) => setOpenInterestChange(e.target.value)}
            required
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Open Price:</label>
          <input
            type="number"
            value={openPrice}
            onChange={(e) => setOpenPrice(e.target.value)}
            required
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">High Price:</label>
          <input
            type="number"
            value={highPrice}
            onChange={(e) => setHighPrice(e.target.value)}
            required
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Low Price:</label>
          <input
            type="number"
            value={lowPrice}
            onChange={(e) => setLowPrice(e.target.value)}
            required
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-gray-100 focus:outline-none focus:border-blue-500"
          />
        </div>
       
      
        <button type="submit" className="w-full py-2 mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md">
          Submit
        </button>
      </form>
      {message && <p className="mt-4 text-center text-green-400">{message}</p>}
    </div>
  );
}

export default PlatinumDetails;
