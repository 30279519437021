import React, { useEffect, useState } from "react";
import axios from "axios";

const platinumApiUrl = `${process.env.REACT_APP_API_URL}api/data2`;
const apiUrl = `${process.env.REACT_APP_API_URL}api/data`;
const platinumUrl = `${process.env.REACT_APP_API_URL}api/get-platinum`;
const goldUrl = `${process.env.REACT_APP_API_URL}api/gold/get-gold`;
const silverUrl = `${process.env.REACT_APP_API_URL}api/silver/get-silver`;

const tokenNameMapping = {
  442268: [{ name: "GOLD", date: "05FEB" }],
  440706: [{ name: "GOLDM", date: "03JAN" }],
  429815: [{ name: "SILVER", date: "05MAR" }],
  429816: [{ name: "SILVERMINI", date: "29FEB" }],
  435357: [{ name: "SILVERMICRO", date: "28FEB" }],
  435823: [
    { name: "CRUDEOIL", date: "18DEC" },
    { name: "PLATINUM", date: "31DEC" },
  ],
  436080: [{ name: "NATURALGAS", date: "26DEC" }],
  437991: [{ name: "COPPER", date: "31DEC" }],
  437987: [{ name: "ZINC", date: "31DEC" }],
  437982: [{ name: "ALUMINIUM", date: "31DESC" }],
};
const orderedNames = Object.values(tokenNameMapping)
  .flat()
  .map((item) => item.name);

function StockTable() {
  const [stockData, setStockData] = useState([]);
  const [platinumAmount, setPlatinumAmount] = useState(0);
  const [platinumData, setPlatinumData] = useState([]);

  useEffect(() => {
    const fetchPlatinumAmount = async () => {
      try {
        const response = await fetch(platinumApiUrl);
        const data = await response.json();
        setPlatinumAmount(data[0]?.amount || 0);
      } catch (error) {
        console.error("Error fetching platinum amount:", error);
      }
    };

    const fetchPlatinum = async () => {
      try {
        const response = await axios.get(platinumUrl);
        setPlatinumData(response.data.platinum);
      } catch (error) {
        console.error("Error fetching platinum amount:", error);
      }
    };

    const fetchStockData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer 1234567890abcdef",
          },
        });
        const data = await response.json();
        const response2 = await axios.get(platinumUrl);
        const response4 = await axios.get(goldUrl);
        const response5 = await axios.get(silverUrl);
        setPlatinumData(response2.data.platinum);
        const response3 = await fetch(platinumApiUrl);
        const data3 = await response3.json();

        console.log(response2.data.platinum);
        updateTableData(data, response2?.data?.platinum, data3[0]?.amount,response4?.data?.platinum,response5?.data?.platinum);
      } catch (error) {
        console.error("Error fetching stock data:", error);
      }
    };

    const updateTableData = (data, data2, data3,gold,silver) => {
      const updatedData = data.flatMap((stock) => {
        const token = stock.token.replace(/"/g, "").trim();
        const mappings = tokenNameMapping[Number(token)] || [];
        return mappings.map(({ name, date }) => {
          let buyPrice =
            (
              parseFloat(stock.best_five_data?.[0]?.buy_sell_price) / 100
            ).toFixed(2) || "N/A";
          let sellPrice =
            (
              parseFloat(stock.best_five_data?.[5]?.buy_sell_price) / 100
            ).toFixed(2) || "N/A";
          if (name === "PLATINUM")
            buyPrice =
              buyPrice !== "N/A"
                ? (parseFloat(buyPrice) + data3).toFixed(2)
                : "N/A";

          if (name === "PLATINUM")
            sellPrice =
              sellPrice !== "N/A"
                ? (parseFloat(sellPrice) + data3).toFixed(2)
                : "N/A";
          let lastTradedPrice =
            (parseFloat(stock.last_traded_price) / 100).toFixed(2) || "N/A";
          let openPrice =
            (parseFloat(stock.open_price_day) / 100).toFixed(2) || "N/A";
          let highPrice =
            (parseFloat(stock.high_price_day) / 100).toFixed(2) || "N/A";
          let lowPrice =
            (parseFloat(stock.low_price_day) / 100).toFixed(2) || "N/A";
          let openInterestChange =
            (
              parseFloat(stock.last_traded_price) / 100 -
              parseFloat(stock.close_price) / 100
            ).toFixed(2) || "N/A";

          if (name === "PLATINUM") {
            openPrice = data2.openPrice.toFixed(2) || "N/A";
            highPrice = data2.highPrice.toFixed(2) || "N/A";
            lowPrice = data2.lowPrice.toFixed(2) || "N/A";
            openInterestChange = (
              parseFloat(openInterestChange) +
              parseFloat(data2.openInterestChange || 0)
            ).toFixed(2);
            lastTradedPrice =
              lastTradedPrice !== "N/A"
                ? (parseFloat(lastTradedPrice) + data3).toFixed(2)
                : "N/A";
          }

          if (name === "GOLD") {
            openPrice = gold.openPrice.toFixed(2) || "N/A";
            highPrice = gold.highPrice.toFixed(2) || "N/A";
            lowPrice = gold.lowPrice.toFixed(2) || "N/A";
            sellPrice =
              (parseFloat(sellPrice) + gold.difference).toFixed(2) || "N/A";
            buyPrice =
              (parseFloat(buyPrice) + gold.difference).toFixed(2) || "N/A";
            lastTradedPrice =
              (parseFloat(lastTradedPrice) + gold.difference).toFixed(2) ||
              "N/A";
              openInterestChange = (
                parseFloat(openInterestChange) +
                parseFloat(gold.openInterestChange || 0)
              ).toFixed(2);
          }

          if (name === "SILVER") {
            openPrice = silver.openPrice.toFixed(2) || "N/A";
            highPrice = silver.highPrice.toFixed(2) || "N/A";
            lowPrice = silver.lowPrice.toFixed(2) || "N/A";
            sellPrice =
              (parseFloat(sellPrice) + silver.difference).toFixed(2) || "N/A";
            buyPrice =
              (parseFloat(buyPrice) + silver.difference).toFixed(2) || "N/A";
            lastTradedPrice =
              (parseFloat(lastTradedPrice) + silver.difference).toFixed(2) ||
              "N/A";
              openInterestChange = (
                parseFloat(openInterestChange) +
                parseFloat(silver.openInterestChange || 0)
              ).toFixed(2);
          }

          return {
            name,
            date,
            buyPrice,
            sellPrice,
            lastTradedPrice,
            openPrice,
            highPrice,
            lowPrice,
            openInterestChange,
          };
        });
      });

      const sortedData = orderedNames.map((name) => {
        const stock = updatedData.find((stock) => stock.name === name);
        return (
          stock || {
            name,
            date: tokenNameMapping[
              Object.keys(tokenNameMapping).find(
                (key) => tokenNameMapping[key][0].name === name
              )
            ][0].date,
            buyPrice: "N/A",
            sellPrice: "N/A",
            lastTradedPrice: "N/A",
            openPrice: "N/A",
            highPrice: "N/A",
            lowPrice: "N/A",
            openInterestChange: "N/A",
          }
        );
      });

      setStockData((prevData) => {
        return sortedData.map((stock, index) => {
          const previousStock = prevData.find(
            (item) => item.name === stock.name
          );
          return {
            ...stock,
            buyPriceChange: previousStock
              ? stock.buyPrice - previousStock.buyPrice
              : null,
            sellPriceChange: previousStock
              ? stock.sellPrice - previousStock.sellPrice
              : null,
          };
        });
      });
    };

    fetchPlatinumAmount();
    //fetchPlatinum();
    const interval = setInterval(fetchStockData, 1000);
    return () => clearInterval(interval);
  }, [platinumAmount]);

  const getCellStyle = (priceChange) => {
    if (priceChange > 0) return { color: "green" };
    if (priceChange < 0) return { color: "red" };
    return { color: "#fff" };
  };

  return (
    <div className="bg-gray-900 text-white font-sans lg:p-6 xl:p-8 2xl:p-10">
      <h1 className="text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold text-gray-100">
        Market Statistics
      </h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 border border-gray-700 rounded-lg shadow-lg">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="p-3 lg:p-4 xl:p-5 2xl:p-6 text-left   bg-gray-700">
                Metal
              </th>
              <th className="p-3 lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700 border">
                Buy Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                Sell Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                Last Traded Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                Open Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                High Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                Low Price
              </th>
              <th className="p-3 border lg:p-4 xl:p-5 2xl:p-6 text-left bg-gray-700">
                Open Interest Change
              </th>
            </tr>
          </thead>
          <tbody>
            {stockData.map((stock, index) => (
              <tr
                key={stock.name}
                className={`text-gray-100 border ${
                  index % 2 === 0 ? "bg-gray-700" : "bg-gray-800"
                }`}
              >
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.name}
                </td>
                <td
                  className="p-3 border lg:p-4 xl:p-5 2xl:p-6"
                  style={getCellStyle(stock.buyPriceChange)}
                >
                  {stock.buyPrice}
                </td>
                <td
                  className="p-3 border lg:p-4 xl:p-5 2xl:p-6"
                  style={getCellStyle(stock.sellPriceChange)}
                >
                  {stock.sellPrice}
                </td>
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.lastTradedPrice}
                </td>
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.openPrice}
                </td>
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.highPrice}
                </td>
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.lowPrice}
                </td>
                <td className="p-3 border lg:p-4 xl:p-5 2xl:p-6">
                  {stock.openInterestChange}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StockTable;
